<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('inventory_transactions.inventory_transaction') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('inventory_transactions.transaction_type') }}<span class="text-danger">*</span></label>
                <select name="transaction_type" id="transaction_type" @input="inputChangedForQTY" v-model="data.transaction_type" class="custom-select">
                  <option v-for="row in transaction_types" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select>
                <span v-if="validation && validation.transaction_type" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.transaction_type[0] }}
                            </span>
              </div>
              <div v-if="data.transaction_type == 3" class="col-lg-12 mb-5">
                <label>{{ $t('inventory_transactions.transfer_from_inventory') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <multiselect v-model="data.transfer_from_inventory"
                               :placeholder="$t('inventory_transactions.transfer_from_inventory')"
                               label="name"
                               track-by="id"
                               :options="inventories"
                               :multiple="false"
                               :class="validation && validation.transfer_from_inventory_id ? 'is-invalid' : ''"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/inventories/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.transfer_from_inventory_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.transfer_from_inventory_id[0] }}
                            </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ (data.transaction_type == 3) ? $t('inventory_transactions.transfer_to_inventory') : $t('inventory_transactions.inventory') }} <span class="text-danger">*</span></label>
                <div class="input-group">
                  <multiselect v-model="data.inventory"
                               :placeholder="(data.transaction_type == 3)? $t('inventory_transactions.transfer_to_inventory') : $t('inventory_transactions.inventory')"
                               label="name"
                               track-by="id"
                               :options="inventories"
                               :multiple="false"
                               :class="validation && validation.inventory_id ? 'is-invalid' : ''"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @input="inputChangedForQTY">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/inventories/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.inventory_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.inventory_id[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('inventory_transactions.transaction_code') }}</label>
                <input type="text" v-model="data.transaction_code" class="form-control" :class="validation && validation.transaction_code ? 'is-invalid' : ''" :placeholder="$t('inventory_transactions.transaction_code')"/>
                <span v-if="validation && validation.transaction_code" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.transaction_code[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('inventory_transactions.transaction_reference') }}</label>
                <input type="text" v-model="data.transaction_reference" class="form-control" :class="validation && validation.transaction_reference ? 'is-invalid' : ''" :placeholder="$t('inventory_transactions.transaction_reference')"/>
                <span v-if="validation && validation.transaction_reference" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.transaction_reference[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('inventory_transactions.transaction_date') }}</label>
                <input type="date" v-model="data.transaction_date" class="form-control" :class="validation && validation.transaction_date ? 'is-invalid' : ''" :placeholder="$t('inventory_transactions.transaction_date')"/>
                <span v-if="validation && validation.transaction_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.transaction_date[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('inventory_transactions.status') }}<span class="text-danger">*</span></label>
                <select name="status" id="status" v-model="data.status" class="custom-select">
                  <option v-for="row in status_list" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-lg-12 pt-8">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('inventory_transactions.products') }}</h6>
            <button type="button" class="btn btn-primary" @click="addToRepeater">{{ $t('add_more') }}</button>
          </div>
          <div class="form-group">
            <div class="table-responsive bg-white">
              <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addToRepeater" @keyup.alt.46="deleteFromRepeater(0)">
                <thead>
                <tr>
                  <th width="250px">{{ $t('items.item_details') }}</th>
                  <th width="300px">
                    <div class="d-flex justify-content-between">
                      <span>{{ $t('inventory_transactions.product') }}</span>
                      <a href="javascript:;" class="btn btn-primary btn-sm p-1" @click="showItemModal">
                        <b-icon icon="plus"></b-icon>
                      </a>
                    </div>
                  </th>
                  <th width="110px">{{ $t('inventory_transactions.purchase_price') }}</th>
                  <th width="140px">{{ $t('inventory_transactions.unit') }}</th>
                  <th width="110px">{{ $t('inventory_transactions.quantity') }}</th>
                  <th width="110px">{{ $t('inventory_transactions.item_inventory_qty_before') }}</th>
                  <th width="110px">{{ $t('inventory_transactions.item_inventory_qty_after') }}</th>
                  <th width="80px">{{ $t('inventory_transactions.total') }}</th>
                  <th width="10px" class="text-center"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(it, index) in data_items" :key="index" :class="getClassValidationRepeaterByIndex(index)">
                  <td>
                    <a :id="'popover-target-'+index" href="javascript:;" v-if="it.item" class="d-flex cursor-pointer">
                      <img :src="it.item.image_url" style="width: 70px; height: 70px;" alt="">
                      <div class="pl-2 pr-2 text-dark">
                        <p class="mb-1" v-if="it.item.sku_code">{{ $t('items.sku_code') }}: {{ it.item.sku_code }}</p>
                        <p class="mb-1" v-if="it.item.name">{{ $t('items.name') }}: {{ it.item.name }}</p>
                        <p class="mb-1" v-if="it.item.sale_price">{{ $t('items.sale_price') }}: {{ it.item.sale_price }}</p>
                        <p class="mb-1" v-if="it.item.purchase_price">{{ $t('items.purchase_price') }}: {{ it.item.purchase_price }}</p>
                        <p class="mb-1" v-if="it.item.sum_available_qty">{{ $t('items.sum_available_qty') }}: {{ it.item.sum_available_qty }}</p>
                      </div>
                    </a>
                  </td>
                  <td>
                    <div class="d-flex search-item-group"  v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('inventory_transactions.product')">
                      <select name="" id="" v-model="it.f_by" class="custom-select" style="width: 100px;">
                        <option value="name" selected>{{ $t('items.name') }}</option>
                        <option value="sku_code">{{ $t('items.sku_code') }}</option>
                      </select>

                      <multiselect v-model="it.item"
                                   :placeholder="$t('inventory_transactions.product')"
                                   :label="it.f_by? it.f_by:'name'"
                                   track-by="id"
                                   :options="items"
                                   :multiple="false"
                                   @input="selectItem(index)"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   :internal-search="false"
                                   @search-change="getItems(it.f_by, $event)">
                      </multiselect>
                    </div>
                    <span v-if="validation && validation[`items.${index}.item`]" class="fv-plugins-message-container invalid-feedback">
                          {{ validation[`items.${index}.item`][0] }}
                        </span>
                  </td>
                  <td>
                    <input type="number" v-model="it.purchase_price" @input="handlingTotalValue(index)" class="form-control" :placeholder="$t('inventory_transactions.purchase_price')"/>
                    <span v-if="validation && validation[`items.${index}.purchase_price`]" class="fv-plugins-message-container invalid-feedback">
                          {{ validation[`items.${index}.purchase_price`][0] }}
                        </span>
                  </td>
                  <td>
                    <multiselect v-model="it.unit"
                                 :placeholder="$t('inventory_transactions.unit')"
                                 label="name"
                                 track-by="id"
                                 :options="(it.item && it.item.units) ? it.item.units:[]"
                                 :multiple="false"
                                 :taggable="false"
                                 @input="setValueUnit(index)"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
                    <span v-if="validation && validation[`items.${index}.unit`]" class="fv-plugins-message-container invalid-feedback">
                            {{ validation[`items.${index}.unit`][0] }}
                          </span>
                    <input v-model="it.units_number" v-if="it.item && it.item.unit && it.unit && it.item.unit.id != it.unit.id" min="0" @input="handlingTotalValue(index)" type="number" class="form-control mt-3">

                  </td>
                  <td>
                    <input type="number" v-model="it.quantity" :disabled="it.item && it.item.unit && it.unit && it.item.unit.id != it.unit.id" @input="handlingTotalValue(index)" class="form-control" :placeholder="$t('inventory_transactions.quantity')"/>
                    <span v-if="validation && validation[`items.${index}.quantity`]" class="fv-plugins-message-container invalid-feedback">
                          {{ validation[`items.${index}.quantity`][0] }}
                        </span>
                  </td>
                  <td>
                    <input type="number" v-model="it.item_inventory_qty_before" disabled class="form-control" :placeholder="$t('inventory_transactions.item_inventory_qty_before')"/>
                  </td>
                  <td>
                    <input type="number" v-model="it.item_inventory_qty_after" disabled class="form-control" :placeholder="$t('inventory_transactions.item_inventory_qty_after')"/>
                  </td>

                  <td>
                    <input type="number" v-model="it.total" :disabled="true" class="form-control" :placeholder="$t('inventory_transactions.total')"/>
                  </td>
                  <td class="text-center">
                    <v-icon class="text-danger" @click="deleteFromRepeater(index)">mdi-delete</v-icon>

                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('inventory_transactions.notes') }}</label>
                  <textarea type="text" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''" :placeholder="$t('inventory_transactions.notes')"></textarea>
                  <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.notes[0] }}
                            </span>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>

      <b-modal ref="modalItem" size="lg" hide-footer :title="$t('items.add_new_item')">
        <item-form @hide-modal="hideItemModal()" @handling-data="getDataAfterCreateNewItem"></item-form>
      </b-modal>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ItemForm from "@/view/content/forms/ItemForm";
import {mapGetters, mapState} from "vuex";
import timeZoneStructure from "@/core/config/mix/timeZoneStructure";

export default {
  name: "from-inventory-transactions",
  components: {'item-form': ItemForm},
  data() {
    return {
      mainRoute: 'items/inventory-transactions',
      mainRouteDependency: 'base/dependency',
      inventories: [],
      items: [],
      units: [],
      transaction_types: [
        {id: 1, name: this.$t('transaction_types.add_quantity')},
        {id: 2, name: this.$t('transaction_types.decrease_quantity')},
        {id: 3, name: this.$t('transaction_types.transfer_quantity')}
      ],
      status_list: [
        {id: 1, name: this.$t('status_list.pending')},
        {id: 2, name: this.$t('status_list.confirm')}
      ],

      idEdit: this.$route.params.id ? this.$route.params.id : null,
      data: {
        transaction_type: null,
        transaction_code: null,
        transaction_reference: null,
        transaction_date: null,
        inventory: null,
        inventory_id: null,
        transfer_from_inventory_id: null,
        transfer_from_inventory: null,
        notes: null,
        status: 1,
      },
      isEditing: false,
      validation: null,
      unit_default: null,
      repeater_items: {id: null, item: null, purchase_price: null, quantity: null, unit: null, total: null, item_inventory_qty_after: null, item_inventory_qty_before: null, units_number: null},
      data_items: [],
      default_unit: [],
      repeater_validation: [],
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {

    'data.inventory': function (val) {
      if (val) {
        this.data.inventory_id = val.id;
        // this.getQuantityForInventory();
        this.items = [];
      } else {
        this.data.inventory_id = null;
        this.items = [];
      }
    },
    'data.transfer_from_inventory': function (val) {
      if (val) {
        this.data.transfer_from_inventory_id = val.id;
      } else {
        this.data.transfer_from_inventory_id = null;
      }
    },

    // data_items: {
    //   handler(val) {
    //     if (val.length > 0) {
    //       this.getQuantityForInventory();
    //     }
    //   },
    //   deep: true
    // },
  },
  methods: {
    ...timeZoneStructure,
    getClassValidationRepeaterByIndex(index) {
      if (index != null) {
        if (this.repeater_validation.includes(index))
            // return 'tr-validation-error';
          return 'border-validation';
      }
      return '';
    },
    validationQTYChecking(_status = true) {
      let _main_validation_status = false;
      this.validation = [];
      this.data_items.forEach((row, index) => {
        let _validation_status = false;

        if (parseFloat(row.purchase_price) <= 0) {
          this.validation[`items.${index}.purchase_price`] = [];
          this.validation[`items.${index}.purchase_price`][0] = this.$t('the_value_must_not_be_less_than_0');
          _validation_status = true;
        }else if(!row.purchase_price && _status){
          this.validation[`items.${index}.purchase_price`] = [];
          this.validation[`items.${index}.purchase_price`][0] = this.$t('the_value_must_not_be_less_than_0');
          _validation_status = true;
        }
        if (parseFloat(row.quantity) <= 0) {
          this.validation[`items.${index}.quantity`] = [];
          this.validation[`items.${index}.quantity`][0] = this.$t('the_value_must_not_be_less_than_0');
          _validation_status = true;
        }else if(!row.quantity && _status){
          this.validation[`items.${index}.quantity`] = [];
          this.validation[`items.${index}.quantity`][0] = this.$t('the_value_must_not_be_less_than_0');
          _validation_status = true;
        }
        if(!row.item && _status){
          this.validation[`items.${index}.item`] = [];
          this.validation[`items.${index}.item`][0] = this.$t('the_item_field_must_be_required');
          _validation_status = true;
        }
        if(!row.unit && _status){
          this.validation[`items.${index}.unit`] = [];
          this.validation[`items.${index}.unit`][0] = this.$t('the_unit_field_must_be_required');
          _validation_status = true;
        }


        if (_validation_status && _status){
          let _index = this.repeater_validation.indexOf(index);
          if (_index < 0) {
            this.repeater_validation.push(index);
          }
        }else{
          let _index = this.repeater_validation.indexOf(index);
          if (_index > -1) {
            this.repeater_validation.splice(_index, 1);
          }
        }

      });
      if (this.repeater_validation.length > 0){
        _main_validation_status = true;
      }
      if (_main_validation_status){
        let _items = [...this.data_items];
        this.data_items = [];
        this.data_items = _items;
        if (_status)
          this.$errorAlertMessage(this.$t('unable_to_send_due_to_data_entry_error'));
      }
      return _main_validation_status;

    },
    refactorValidation(){
      this.validationQTYChecking(false);
    },

    save(event) {
      if (this.validationQTYChecking()) {
        event.preventDefault();
        return false;
      } else {
        if (this.isEditing) {
          this.update();
        } else {
          this.create();
        }
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
        items: this.data_items,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/items/inventory-transactions');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
        items: this.data_items,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/items/inventory-transactions');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data = response.data.data;
        this.data_items = response.data.data.items.map((row) => {
          let _qty = row.quantity ? parseFloat(row.quantity) : 0;
          let _purchase_price = row.purchase_price ? parseFloat(row.purchase_price) : 0;
          let _total = _qty * _purchase_price;
          row.total = _total;
          return row;
        });
        if (response.data.data.items.length <= 0) {
          this.addToRepeater();
        }
      });
    },

    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      // if (filter && filter.length >= 3 && _f_by && this.data.inventory_id)
      if (filter && filter.length >= 3 && _f_by)
          // ApiService.get(`${this.mainRouteDependency}/itemsBasedStatistics/${this.data.inventory_id}`, {params: {[_f_by]: filter}}).then((response) => {
        ApiService.get(`${this.mainRouteDependency}/items`, {params: {[_f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },

    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },

    // async getUnits() {
    //   await ApiService.get(this.mainRouteDependency + "/units").then((response) => {
    //     this.units = response.data.data;
    //   });
    // },


    addToRepeater() {
      // this.repeater_items.unit = this.unit_default;
      // this.repeater_items.unit = this.default_unit;
      this.data_items.unshift(this.repeater_items);
      this.repeater_items = {id: null, item: null, purchase_price: null, quantity: null, unit: null, total: null, item_inventory_qty_after: null, item_inventory_qty_before: null, units_number: null};
      this.refactorValidation();
      },
    deleteFromRepeater(index) {
      if (this.data_items.length > 1){
        this.data_items.splice(index, 1);
        this.refactorValidation();
      }
    },
    handlingValue(index) {
      this.data_items[index].purchase_price = this.data_items[index].item.purchase_price;
      // if (this.data_items[index].item && this.data_items[index].item.unit){
      //   this.data_items[index].unit = this.data_items[index].item.unit;
      // }
      this.data_items[index].unit = null;

      if (this.data_items[index].item && this.data_items[index].item.units && this.data_items[index].item.units.length) {
        this.data_items[index].unit = this.data_items[index].item.units.find(row => row.is_main);
      }
      // this.inputChangedForQTY();
    },
    handlingTotalValue(index) {
      this.zeroForNegative();

      if (this.data_items[index].item && this.data_items[index].unit && this.data_items[index].item.unit) {
        if (this.data_items[index].item.unit.id != this.data_items[index].unit.id) {
          if (this.data_items[index].item.is_min) {
            this.data_items[index].quantity = ((this.data_items[index].unit.number ? parseFloat(this.data_items[index].unit.number) : 0) * (this.data_items[index].units_number ? parseFloat(this.data_items[index].units_number) : 0)).toFixed(3);
          } else {
            this.data_items[index].quantity = ((this.data_items[index].units_number ? parseFloat(this.data_items[index].units_number) : 0) / (this.data_items[index].unit.number ? parseFloat(this.data_items[index].unit.number) : 0)).toFixed(3);
          }
        } else {
          this.data_items[index].units_number = 0;
        }
      }

      let _purchase_price = this.data_items[index].purchase_price;
      _purchase_price = (_purchase_price ? _purchase_price : 0);
      let _quantity = this.data_items[index].quantity;
      _quantity = (_quantity ? _quantity : 0);
      if (_quantity < 0 || isNaN(_quantity)) {
        this.data_items[index].quantity = 0;
        _quantity = 0;
      }
      this.data_items[index].total = (_purchase_price * _quantity).toFixed(2);

      this.inputChangedForQTY();
    },
    selectItem: _.debounce(function (index) {

      this.data_items[index].item_id = this.data_items[index].item ? this.data_items[index].item.id : null;

      this.data_items[index].unit = null;

      if (this.data_items[index].item && this.data_items[index].item.units && this.data_items[index].item.units.length) {
        this.data_items[index].unit = this.data_items[index].item.units.find(row => row.is_main);
      }

      this.setValueUnit(index);
    }, 100),
    setValueUnit(index) {
      this.data_items[index].quantity = 0;
      this.data_items[index].units_number = 0;
      this.handlingTotalValue(index);
    },
    getDataAfterCreateNewItem(object) {
      let promise = this.getItems();
      Promise.all([promise]).then(() => {
        if (object) {
          this.data_items[0].item = object;
          this.data_items[0].purchase_price = object.purchase_price;
        }
      })
    },

    showItemModal() {
      this.$refs['modalItem'].show()
    },
    hideItemModal() {
      this.$refs['modalItem'].hide();
    },

    async getQuantityForInventory() {
      let _ids = [];
      this.data_items.forEach((row) => {
        if (row.item && row.item.id)
          _ids.push(row.item.id);
      });
      if (this.data.inventory && _ids.length > 0)
        await ApiService.query(this.mainRouteDependency + "/getQtyInventory/" + this.data.inventory.id, {ids: _ids}).then((response) => {

          this.data_items.forEach((row, index) => {

            this.data_items[index].item_inventory_qty_before = 0;
            this.data_items[index].item_inventory_qty_after = this.data_items[index].quantity;

            response.data.data.forEach((row_data) => {
              if (row.item && (row.item.id == row_data.product_id)) {
                this.data_items[index].item_inventory_qty_before = row_data.available_stock_qty;

                if (this.data.transaction_type == 2) {
                  let _qty = (this.data_items[index].quantity ? parseFloat(this.data_items[index].quantity) : 0);
                  let _available_stock_qty = (row_data.available_stock_qty ? parseFloat(row_data.available_stock_qty) : 0);
                  if (_qty > _available_stock_qty) {
                    this.data_items[index].quantity = _available_stock_qty;
                    _qty = _available_stock_qty;
                  }
                  if (_qty < 0) {
                    this.data_items[index].quantity = 0;
                    _qty = 0;
                  }
                  this.data_items[index].item_inventory_qty_after = (_available_stock_qty - _qty);
                } else {
                  this.data_items[index].item_inventory_qty_after = ((row_data.available_stock_qty ? parseFloat(row_data.available_stock_qty) : 0) + (this.data_items[index].quantity ? parseFloat(this.data_items[index].quantity) : 0));

                }
              }

            });
          });

        });
    },

    inputChangedForQTY() {
      this.getQuantityForInventory();
    },

    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.inventory_id = response.data.data.inventory_id;
        this.data.inventory = response.data.data.inventory;
        // this.repeater_items.unit = response.data.data.unit;
        // this.default_unit = response.data.data.unit;
        // if (this.data_items[0]) {
        //   this.data_items[0].unit = response.data.data.unit;
        // }
      });
    },
    zeroForNegative() {
      this.data_items = this.data_items.map((row) => {
        if(String(row.purchase_price).length > 12){
          row.purchase_price = String(row.purchase_price).slice(0, 12);
        }
        if(String(row.quantity).length > 9){
          row.quantity = String(row.quantity).slice(0, 9);
        }

        if (isNaN(row.purchase_price)) {
          row.purchase_price = 0;
        } else if (parseFloat(row.purchase_price) < 0) {
          row.purchase_price = 0;
        }
        if (isNaN(row.quantity)) {
          row.quantity = 0;
        } else if (parseFloat(row.quantity) < 0) {
          row.quantity = 0;
        }

        if (isNaN(row.units_number)) {
          row.units_number = 0;
        } else if (parseFloat(row.units_number) < 0) {
          row.units_number = 0;
        }

        return row;

      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.Inventory_transactions"), route: '/items/inventory-transactions'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    this.getItems();
    this.getInventories();
    // this.getUnits();

    if (this.idEdit) {
      this.getData();
    } else {
      this.defaultDataForUser();
      if (this.data_items.length <= 0) {
        this.addToRepeater();
      }
      // this.data.transaction_date = new Date().toISOString().slice(0, 10);
      this.getTimeZone().then((res)=>{
        this.data.transaction_date = res;
      });
    }

  },
};
</script>


